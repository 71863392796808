<template>
  <b-dialog v-model="visible" width="700px" title="专题论述">
    <el-form ref="form" class="g-p-20" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="目录" prop="content">
        <el-select
          v-model="formData.menu_id"
          ref="select"
          clearable
          filterable
          allow-create
          default-first-option
          placeholder="输入或者选择目录"
        >
          <el-option v-for="item in menuList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <el-input
          v-model="formData.content"
          type="textarea"
          :autosize="{ minRows: 3 }"
          placeholder="输入内容"
        ></el-input>
      </el-form-item>

      <el-form-item label="来源出处" prop="source">
        <el-input v-model="formData.source" placeholder="输入内容"></el-input>
      </el-form-item>

      <el-form-item label="讲话日期" prop="date">
        <el-date-picker v-model="formData.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>

      <div style="text-align: right;">
        <b-btn-confirm :loading="submitLoading" @click="onSubmit"></b-btn-confirm>
      </div>
    </el-form>
  </b-dialog>
</template>

<script>
import { getMenuPage, createDetail, updateDetail } from '@/api/farming/index'

export default {
  data() {
    return {
      visible: false,
      submitLoading: false,
      menuList: [],
      formData: {},
      formRules: {
        menu_id: [{ required: true, message: '请选择或者输入目录', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        source: [{ required: true, message: '请填写来源出处', trigger: 'blur' }],
        date: [{ required: true, message: '请选择讲话日期', trigger: 'change' }],
      },
    }
  },
  methods: {
    open(val) {
      this.fetchList()
      this.formData = val
      this.visible = true
    },
    fetchList() {
      getMenuPage().then(res => {
        this.menuList = res
      })
    },
    onSubmit() {
      console.log(this.$refs.select.createdLabel, this.$refs.select.selectedLabel, this.$refs.select.currentLabel)

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const params = {
            content: this.formData.content,
            source: this.formData.source,
            date: this.formData.date,
          }
          if (this.$refs.select.selectedLabel === this.$refs.select.createdLabel) {
            params.menu_title = this.formData.menu_id
          } else {
            params.menu_id = this.formData.menu_id
            params.menu_title = this.$refs.select.selectedLabel
          }
          if (!this.formData.id) {
            createDetail(params)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            updateDetail(this.formData.id, params)
              .then(res => {
                this.$message.success('修改成功')
                this.$emit('success')
                this.visible = false
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
